/* Required für Safari */

if (navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1) {
    var forms = document.getElementsByTagName('form');
    for (var i = 0; i < forms.length; i++) {
        forms[i].noValidate = true;

        forms[i].addEventListener('submit', function (event) {
            //Prevent submission if checkValidity on the form returns false.
            if (!event.target.checkValidity()) {
                event.preventDefault();
                alert("Bitte alle Felder mit * ausfüllen!");
                markRequired();

            }
        }, false);
    }
}

function markRequired() {
    $('form').find('input, select, checkbox').each(function () {
        if ($(this).prop('required')) {
            if (!$(this).val()) {
                $(this).addClass('required');

            }
        }
    });
}


/* Anmeldung slideable */

$(".anmeldungheading").click(function () {
    $(this).siblings().slideToggle();
});

/* Skel */

(function ($) {

    skel.breakpoints({
        xlarge: '(max-width: 1680px)',
        large: '(max-width: 1280px)',
        medium: '(max-width: 980px)',
        small: '(max-width: 736px)',
        xsmall: '(max-width: 480px)'
    });

    $(function () {

        var $window = $(window),
            $body = $('body'),
            $html = $('html');

        // Disable animations/transitions until the page has loaded.
        $body.addClass('is-loading');

        $window.on('load', function () {
            window.setTimeout(function () {
                $body.removeClass('is-loading');
            }, 0);
        });

        // Fix: Placeholder polyfill.
        $('form').placeholder();

        // Prioritize "important" elements on medium.
        skel.on('+medium -medium', function () {
            $.prioritize(
                '.important\\28 medium\\29',
                skel.breakpoint('medium').active
            );
        });

        // Dropdowns.
        $('#nav > ul').dropotron({
            alignment: 'center',
            hideDelay: 350,
            expandMode: 'click'
        });

        // Off-Canvas Navigation.

        // Navigation Button.
        $(
            '<div id="navButton">' +
            '<a href="#navPanel" class="toggle"></a>' +
            '</div>'
        )
            .appendTo($body);

        // Navigation Panel.
        $(
            '<div id="navPanel">' +
            '<nav>' +
            $('#nav').navList() +
            '</nav>' +
            '</div>'
        )
            .appendTo($body)
            .panel({
                delay: 500,
                hideOnClick: true,
                resetScroll: true,
                resetForms: true,
                side: 'top',
                target: $html,
                visibleClass: 'navPanel-visible'
            });

        // Fix: Remove transitions on WP<10 (poor/buggy performance).
        if (skel.vars.os == 'wp' && skel.vars.osVersion < 10)
            $('#header, #navButton, #navPanel, #page-wrapper')
                .css('transition', 'none');

        // IE8 fixes.
        if (skel.vars.IEVersion < 9) {

            // Odd/even on wrapper.split.
            $('.wrapper.split .secondary > section').each(function (i) {
                $(this).addClass((i + 1) % 2 == 0 ? 'even' : 'odd');
            });

        }

    });

})(jQuery);

// An Freund verschicken

$('#freundNeu').click(function () {
    $(".eventVerschickenNeu").toggle(this.checked);
});

$('#freund').click(function () {
    $(".eventVerschicken").toggle(this.checked);
});

// Accordion

$('.accordion-content').hide();
$('.accordion-heading').prepend('<div class="accordion-arrow">&nbsp;</div>');
$('.accordion-heading').click(function () {
    $(this).closest('li').find('div.accordion-content').slideToggle(100);
    $(this).closest('li').find('div.accordion-arrow').toggleClass('spin');
});

// Chat direkt von Anfang an ausklappen

function showBuddies() {
    //alert("Buddies werden gezeigt");
    $("#arrowchat_buddy_list_tab").trigger("click");
}

$(document).ready(function () {
    setTimeout(showBuddies, 1000);
    //showBuddies();
});

/* Icons für den Chat live anzeigen; statt dem "Icon-Code" */

// Umwandeln in zwei surrogate 16-bit code units:
function surrogate(cp) {
    return [
        ((cp - 0x010000 & 0xffc00) >> 10) + 0xd800,
        (cp - 0x010000 & 0x3ff) + 0xdc00
    ];
};

/*
ArrowFunctions werden in Safari 9 noch nicht unterstützt, deshalb untenstehende Version 

function convertEmoji(str) {
    return str.replace(/\[e-([0-9a-fA-F]+)\]/g, (match, hex) =>
        String.fromCharCode.apply(null, surrogate(Number.parseInt(hex, 16)))
    );
}; */

function convertEmoji(a){return a.replace(/\[e-([0-9a-fA-F]+)\]/g,function(a,b){return String.fromCharCode.apply(null,surrogate(Number.parseInt(b,16)))})};

function showEmojis(){
    $('.arrowchat_textarea').val(function (index, value) {
            return convertEmoji(value)
    })
};

/* Einzige Möglichkeit die funktioniert */

var iconsSichtbar = setInterval(function() {
   if($('.arrowchat_smiley_box').is(':visible')) {
          showEmojis();
          setTimeout(showEmojis, 100);  
   }
}, 1000);

// Startseite Button Hover Box
    
var $ukBadgeButtons = $(".uk-badge.uk-badge-purple.uk-badge-small");
$ukBadgeButtons.each(function(key, value) {
    var $button = $(value);
    if($button.attr("title").trim()) {
        var $hoverBox = $("<div class=\"hover-box\"></div>").text($button.attr("title"));
        $button.attr("title", "");
        $button.click(function(event) {
            $button.append($hoverBox);
        });
        $button.hover(function(event) {
            $button.append($hoverBox);
        }, function() {
            $button.find(".hover-box").remove();
        });
        $button.mousemove(function(event) {
            var offsets = $button.offset();
            $hoverBox.css({
                left: event.pageX - offsets.left,
                top: event.pageY - offsets.top
            })
        });
    }
});
